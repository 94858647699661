define("discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pfaffmanager">
    {{#if this.server.is_pds_server}}
      {{#if this.server.active}}
        <p>
          <a href="https://{{this.server.hostname}}">{{this.server.hostname}}</a>
          is a PDS server running version
          {{this.server.pds_server_status.version}}. You can see the server's
          status
          <a href="https://{{this.server.hostname}}/xrpc/_health">here</a>. One
          day, this site will offer more information about the server, its status,
          and tools to create new users, but for now, you can only see the
          server's status.
        </p>
        <p>If you need help with anything, please send a message to @pfaffman</p>
      {{else}}
        {{server-item server=this.server}}
      {{/if}}
    {{else}}
      {{server-item server=this.server}}
    {{/if}}
  </div>
  */
  {
    "id": "L8VsGjpC",
    "block": "[[[10,0],[14,0,\"pfaffmanager\"],[12],[1,\"\\n\"],[41,[30,0,[\"server\",\"is_pds_server\"]],[[[41,[30,0,[\"server\",\"active\"]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[10,3],[15,6,[29,[\"https://\",[30,0,[\"server\",\"hostname\"]]]]],[12],[1,[30,0,[\"server\",\"hostname\"]]],[13],[1,\"\\n        is a PDS server running version\\n        \"],[1,[30,0,[\"server\",\"pds_server_status\",\"version\"]]],[1,\". You can see the server's\\n        status\\n        \"],[10,3],[15,6,[29,[\"https://\",[30,0,[\"server\",\"hostname\"]],\"/xrpc/_health\"]]],[12],[1,\"here\"],[13],[1,\". One\\n        day, this site will offer more information about the server, its status,\\n        and tools to create new users, but for now, you can only see the\\n        server's status.\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"If you need help with anything, please send a message to @pfaffman\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"server\"],[[30,0,[\"server\"]]]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"server\"],[[30,0,[\"server\"]]]]]],[1,\"\\n\"]],[]]],[13]],[],false,[\"div\",\"if\",\"p\",\"a\",\"server-item\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-show.hbs",
    "isStrictMode": false
  });
});