define("discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-group-index", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    this doesn't get used right now. It would let you get an index of all available
  groups?
  
  <div class="pfaffmanager-index">
    <h2>
      Server List for
      {{i18n "pfaffmanager.server.x_servers" count=servers.length}}
    </h2>
    {{#each servers as |server|}}
      {{compact-server-item server=server}}
    {{/each}}
  </div>
  */
  {
    "id": "hM5DFUHe",
    "block": "[[[1,\"this doesn't get used right now. It would let you get an index of all available\\ngroups?\\n\\n\"],[10,0],[14,0,\"pfaffmanager-index\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,\"\\n    Server List for\\n    \"],[1,[28,[35,2],[\"pfaffmanager.server.x_servers\"],[[\"count\"],[[30,0,[\"servers\",\"length\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"servers\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,5],null,[[\"server\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[13],[1,[28,[32,0],[\"[[\\\"The `servers` property path was used in the `discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-group-index.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.servers}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `servers` property path was used in the `discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-group-index.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.servers}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"server\"],false,[\"div\",\"h2\",\"i18n\",\"each\",\"-track-array\",\"compact-server-item\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/pfaffmanager-servers-group-index.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});