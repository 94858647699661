define("discourse/plugins/discourse-pfaffmanager/discourse/templates/user/servers/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <form>
    {{server-item server=this.server}}
    <div class="pull-right">
      {{#if this.server.server_status_json}}
        {{d-button
          label="pfaffmanager.server.run_upgrade"
          action=(action "this.updateServer")
          icon="check"
          class="btn btn-primary"
        }}
      {{else}}
        {{d-button
          label="pfaffmanager.server.update"
          action=(action "this.updateServer")
          icon="check"
          class="btn btn-primary"
        }}
      {{/if}}
    </div>
    {{#if this.server.encrypted_do_api_key}}
      {{#if this.server.encrypted_mg_api_key}}
        {{#unless this.server.server_status_json}}
  
          <div class="pull-right">
            {{d-button
              label="pfaffmanager.server.do_create"
              action=(action "this.dropletCreate")
              icon="plus"
              class="btn btn-primary"
            }}
          </div>
        {{/unless}}
      {{/if}}
    {{/if}}
  </form>
  */
  {
    "id": "FYo84SmD",
    "block": "[[[10,\"form\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"server\"],[[30,0,[\"server\"]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n\"],[41,[30,0,[\"server\",\"server_status_json\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"label\",\"action\",\"icon\",\"class\"],[\"pfaffmanager.server.run_upgrade\",[28,[37,5],[[30,0],\"this.updateServer\"],null],\"check\",\"btn btn-primary\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"label\",\"action\",\"icon\",\"class\"],[\"pfaffmanager.server.update\",[28,[37,5],[[30,0],\"this.updateServer\"],null],\"check\",\"btn btn-primary\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"server\",\"encrypted_do_api_key\"]],[[[41,[30,0,[\"server\",\"encrypted_mg_api_key\"]],[[[41,[51,[30,0,[\"server\",\"server_status_json\"]]],[[[1,\"\\n        \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n          \"],[1,[28,[35,4],null,[[\"label\",\"action\",\"icon\",\"class\"],[\"pfaffmanager.server.do_create\",[28,[37,5],[[30,0],\"this.dropletCreate\"],null],\"plus\",\"btn btn-primary\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null],[13]],[],false,[\"form\",\"server-item\",\"div\",\"if\",\"d-button\",\"action\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/user/servers/show.hbs",
    "isStrictMode": false
  });
});