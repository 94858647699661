define("discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/admin-user-details/pfaffmanager-servers", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>Pfaffmanager servers</h3>
  <ul>
    {{#each model.servers as |server|}}
      <li>
        <a href="/pfaffmanager/servers/{{server.id}}">{{server.hostname}}</a>
        at
        <a href={{server.discourse_url}}>{{server.discourse_url}}</a>
      </li>
    {{/each}}
  </ul>
  */
  {
    "id": "LWGkYXDH",
    "block": "[[[10,\"h3\"],[12],[1,\"Pfaffmanager servers\"],[13],[1,\"\\n\"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\",\"servers\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[\"/pfaffmanager/servers/\",[30,1,[\"id\"]]]]],[12],[1,[30,1,[\"hostname\"]]],[13],[1,\"\\n      at\\n      \"],[10,3],[15,6,[30,1,[\"discourse_url\"]]],[12],[1,[30,1,[\"discourse_url\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/admin-user-details/pfaffmanager-servers.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"server\"],false,[\"h3\",\"ul\",\"each\",\"-track-array\",\"li\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/admin-user-details/pfaffmanager-servers.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});