define("discourse/plugins/discourse-pfaffmanager/discourse/components/modal/really-delete", ["exports", "@ember/component", "discourse/mixins/buffered-content", "@ember/template-factory"], function (_exports, _component, _bufferedContent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <DModal
      @reallyDeleteModal={{@closeModal}}
      class="really-delete-modal"
      @title={{i18n "pfaffmanager.server.delete_title"}}
      @closeModal={{@closeModal}}
    >
      <:body>
        <DButton
          class="btn btn-primary"
          @action={{route-action
            "deleteServer"
            (hash server=this.model id=this.model.id)
          }}
          @title="pfaffmanager.server.delete_title"
          @icon="trash-can"
          @label="pfaffmanager.server.delete"
        />
      </:body>
      <:footer>
        {{i18n "pfaffmanager.server.delete_warning"}}
      </:footer>
    </DModal>
  </div>
  */
  {
    "id": "m+JU0LTu",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"really-delete-modal\"]],[[\"@reallyDeleteModal\",\"@title\",\"@closeModal\"],[[30,1],[28,[37,2],[\"pfaffmanager.server.delete_title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n      \"],[8,[39,4],[[24,0,\"btn btn-primary\"]],[[\"@action\",\"@title\",\"@icon\",\"@label\"],[[28,[37,5],[\"deleteServer\",[28,[37,6],null,[[\"server\",\"id\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"id\"]]]]]],null],\"pfaffmanager.server.delete_title\",\"trash-can\",\"pfaffmanager.server.delete\"]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[1,[28,[35,2],[\"pfaffmanager.server.delete_warning\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13]],[\"@closeModal\"],false,[\"div\",\"d-modal\",\"i18n\",\":body\",\"d-button\",\"route-action\",\"hash\",\":footer\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/components/modal/really-delete.hbs",
    "isStrictMode": false
  });
  const reallyDeleteModal = _component.default.extend((0, _bufferedContent.bufferedProperty)("server"), {
    didInsertElement() {
      this._super();
    },
    doSomething() {
      window.console.log("this is not much, but something");
    }

    // @afterRender
    // },

    // @discourseComputed("post.shareUrl", "topic.shareUrl")
    // url(postUrl, topicUrl) {
    //   if (postUrl) {
    //     return getAbsoluteURL(postUrl);
    //   } else if (topicUrl) {
    //     return getAbsoluteURL(topicUrl);
    //   }
    // },
  });

  // ShareTopicModal.reopenClass({
  //   modalClass: "really-delete-modal",
  // });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, reallyDeleteModal);
});